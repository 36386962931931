import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { isLogin, getUsernameFromStorage } from './apis/auth/authApi';

import { connect } from 'react-redux';
import { loadUserAction } from './actions/index';
import { getUser } from './apis/user/userApi';
import './i18n';

const loading = () => <div className="animated fadeIn pt-3 text-center">稍安勿躁,马上就来...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout.js'));
const UserLayout = React.lazy(() => import('./containers/UserLayout.js'));
const RootLayout = React.lazy(() => import('./containers/RootLayout.js'));
const OrgUserConfigLayout = React.lazy(() => import('./containers/OrgUserConfigLayout.js'));

// Pages
const RegisterSuccess = React.lazy(() => import('./views/Pages/RegisterSuccess'));
const Reset = React.lazy(() => import('./views/Pages/Reset'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));


class App extends Component {
  constructor() {
    super();
    this.state = {
      user: null
    };
  }

  componentDidMount() {
    if(isLogin()) {
      getUser(getUsernameFromStorage()).then(user => {
        this.props.loadUserAction(user);
      });
    }
  }

  render() {
    return (
      <BrowserRouter basename="/">
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/registerSuccess" name="Register success page" render={props => <RegisterSuccess {...props} />} />
              <Route exact path="/resetUser/:username/:activateToken" name="Reset Page" render={props => <Reset {...props} />} />
              <Route exact path="/login" name="Login Page" render={props => <DefaultLayout {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <DefaultLayout {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/org" name="Organization User Page" render={props => this.props.user && isLogin() ? <OrgUserConfigLayout {...props}/> : <DefaultLayout {...props}/>} />
              <Route path="/root" name="Root Page" render={props => this.props.user && isLogin() ? <RootLayout {...props}/> : <DefaultLayout {...props}/>} />
              <Route path="/" name="Home" render={props => this.props.user && isLogin() ? <UserLayout {...props}/> : <DefaultLayout {...props}/> } />                                        
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUserAction: (user) => dispatch(loadUserAction(user))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
